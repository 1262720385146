<template>
  <custom-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <template v-if="item && pages && pages.length > 0">
                <v-list two-line>
                  <draggable
                    v-model="pages"
                    :disabled="pages.length == 1"
                    handle=".handle"
                    class="list-group"
                    v-bind="dragOptions"
                    @update="update"
                    draggable=".draggable"
                  >
                    <transition-group type="transition" name="flip-list">
                      <v-list-item
                        v-for="(page, idx) in pages"
                        :key="page.id"
                        :to="{ name: 'pages_show', params: { id: page.id } }"
                        class="page-item draggable"
                      >
                        <v-list-item-avatar>
                          <v-icon> mdi-file-document </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-uppercase"
                            v-text="idx + 1 + '. ' + page.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar
                          class="handle"
                          v-if="pages.length > 1"
                        >
                          <v-icon> mdi-reorder-horizontal </v-icon>
                        </v-list-item-avatar>
                      </v-list-item>
                    </transition-group>
                  </draggable>
                </v-list>
              </template>
              <template v-else>
                <div class="text-center">
                  <h3 class="display-1 font-weight-light text--primary my-5">
                    No se han creado páginas en este sección.
                  </h3>
                  <div class="d-flex justify-space-around pa-2">
                    <va-action-button
                      :to="{
                        name: 'pages_create',
                        params: { parent: item },
                      }"
                      icon="mdi-file-document-edit"
                      label="Crear Página"
                      color="#af4d0d"
                    />
                  </div>
                </div>
              </template>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-8">
            <v-list color="transparent" subheader v-if="item">
              <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-book-open-page-variant </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ chapter.book.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-book-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ chapter.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-star </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Creado {{ $helpers.dateFromNow(item.created_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-pen </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Modificado {{ $helpers.dateFromNow(item.updated_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list color="transparent" subheader>
              <v-subheader>Acciones</v-subheader>
              <v-list-item
                :to="{ name: 'pages_create', params: { parent: item } }"
              >
                <v-list-item-icon>
                  <v-icon> mdi-file-document-edit </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Crear página</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </custom-show-layout>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["item", "parent"],
  components: {
    draggable,
  },
  data() {
    return {
      title: this.item.title,
      chapter: this.parent ?? this.item.chapter,
      pages: this.item.children,
    };
  },
  methods: {
    async update() {
      this.pages.map((el, index) => {
        el.priority = index + 1;
        this.$store.dispatch("pages/update", {
          id: el.id,
          data: { priority: el.priority },
        });
      });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        ghostClass: "ghost",
        forceFallback: true,
        fallbackClass: "clone_card",
      };
    },
  },
};
</script>
