export default [
  { name: "users", icon: "mdi-account-cog", routes: ["list"] },
  {
    name: "books",
    icon: "mdi-notebook",
    label: "name",
    include: { chapters: [Array] },
    except: ['clone']
  },
  //{ name: "customers", icon: "mdi-stethoscope", label: "name" },
  {
    name: "chapters",
    icon: "mdi-book-variant-multiple",
    label: "title",
    include: { book: [Array], pages: [Array] },
    except: ["list"],
    resourceParent: "books",
  },
  {
    name: "pages",
    icon: "mdi-file-document",
    label: "title",
    include: { chapter: [Array], section: [Array] },
    except: ["list"],
    resourceParent: "chapters",
  },
  {
    name: "sections",
    api: "pages",
    icon: "mdi-bookmark-multiple",
    label: "title",
    include: { children: [Array] },
    except: ["list"],
    resourceParent: "chapters",
  },
  { name: "stats", icon: "mdi-chart-line", label: "title" },
  {
    name: "stat_sections",
    icon: "mdi-chart-box-outline",
    label: "title",
    include: { children: [Array] },
    except: ["list"],
    resourceParent: "stats",
  },
  {
    name: "stat_question_sections",
    api: "stat_questions",
    icon: "mdi-format-list-checks",
    label: "title",
    include: { children: [Array] },
    except: ["list"],
    resourceParent: "stat_sections",
  },
  {
    name: "stat_questions",
    icon: "mdi-beaker-question-outline",
    label: "title",
    include: { children: [Array] },
    except: ["list"],
    resourceParent: "stat_sections",
  },
  {
    name: "stat_question_options",
    icon: "mdi-timeline-check-outline",
    label: "title",
    except: ["list", "show", "create", "edit"],
    resourceParent: "stat_questions",
  },
];
