<template>
  <custom-edit-layout>
    <stat-sections-form
      :id="id"
      :title="title"
      :item="item"
      :stat="stat"
    ></stat-sections-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: "Editar variable: " + this.item.title,
      stat: this.item.stat,
    };
  },
};
</script>