<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Guías</div>
          </template>
          <v-card-text>
            <va-list
              resource="books"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :items-per-page="3"
              :items-per-page-options="[3, 6, 9, 18, 36]"
              disable-export
            >
              <template v-slot="{ items }">
                <v-row>
                  <v-col cols="12" v-for="item in items" :key="item.id">
                    <custom-material-card-list-item
                      color="info"
                      icon="mdi-book-open-page-variant"
                      :title="item.title"
                      sub-icon="mdi-clock"
                      :sub-text="$helpers.dateFromNow(item.updated_at)"
                      :to="{ name: 'books_show', params: { id: item.id } }"
                      class="text-uppercase"
                    />
                  </v-col>
                </v-row>
              </template>
            </va-list>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="6">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Estratificaciones</div>
          </template>
          <v-card-text>
            <va-list
              resource="stats"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :items-per-page="3"
              :items-per-page-options="[3, 6, 9, 18, 36]"
              disable-export
            >
              <template v-slot="{ items }">
                <v-row>
                  <v-col cols="12" v-for="item in items" :key="item.id">
                    <custom-material-card-list-item
                      color="info"
                      icon="mdi-chart-bar"
                      :title="item.title"
                      sub-icon="mdi-clock"
                      :sub-text="$helpers.dateFromNow(item.updated_at)"
                      :to="{ name: 'stats_show', params: { id: item.id } }"
                      class="text-uppercase"
                    />
                  </v-col>
                </v-row>
              </template>
            </va-list>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: ["title"],
      sortBy: ["updated_at"],
      sortDesc: [true],
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id",
        },
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Salary",
          value: "salary",
          align: "right",
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right",
        },
        {
          sortable: false,
          text: "City",
          value: "city",
          align: "right",
        },
      ],
      //items:  this.$admin.dataProvider.getList("books"),
      tabs: 0,
      tasks: {
        0: [
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false,
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true,
          },
        ],
        1: [
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    async getBooks(method, url) {
      try {
        await this.$admin.http({ method, url });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    }
  },
};
</script>
