<template>
  <custom-edit-layout>
    <stat-question-sections-form :id="id" :title="title" :item="item" :section="section"></stat-question-sections-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: "Editar epígrafe: " + this.item.title,
      section: this.item.section,
    };
  },
};
</script>
