<template>
  <custom-edit-layout>
    <stats-form :id="id" :title="title" :item="item"></stats-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
