<template>
  <va-create-layout>
    <pages-form
      :title="title"
      :parent="parent"
      :parentRes="parentRes"
      :section="section"
      :chapter="chapter"
    />
  </va-create-layout>
</template>

<script>
export default {
  props: ["parent", "item"],
  data() {
    return {
      title: "Crear nueva páginas",
      section:
        typeof this.parent.children == "object" ? this.parent : undefined,
      chapter:
        typeof this.parent.children == "undefined"
          ? this.parent
          : this.parent.chapter,
      parentRes:
        typeof this.parent.children == "undefined" ? "chapters" : "sections",
    };
  },
};
</script>
