<template>
  <custom-edit-layout>
    <chapters-form
      :id="id"
      :title="title"
      :item="item"
      :book="book"
    ></chapters-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: this.item.title,
      book: this.item.book,
    };
  },
};
</script>
