<template>
  <va-create-layout>
    <stat-question-sections-form :title="title" :section="parent"></stat-question-sections-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["parent", "item"],
  data() {
    return {
      title: "Crear nuevo epígrafe de preguntas",
    };
  },
};
</script>