/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/",
  },
  { divider: true },
  { heading: i18n.t("menu.books") },
  ...admin.getResourceLinks([
    "books"
  ]),
  { divider: true },
  { heading: i18n.t("menu.stats") },
  ...admin.getResourceLinks([
    "stats"
  ]),
  { divider: true },
  { heading: i18n.t("menu.other") },
  ...admin.getResourceLinks([
    "customers",
    "users"
  ]),
];
