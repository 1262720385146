<template>
  <custom-edit-layout>
    <books-form :id="id" :title="title" :item="item"></books-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
