import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import upperFirst from "lodash/upperFirst";
import truncate from "lodash/truncate";
import replace from "lodash/replace";

export default ({ store, i18n, resource, title }) => {
  let { name, include, routes, translatable, getTitle, pluralName } = resource;

  const setTitle = (to, action, item = null) => {
    to.meta.title = item ? item.title : getTitle(action, item);

    /**
     * Set main and document title
     */
    document.title = `${to.meta.title} | ${title}`;

    return title;
  };

  const splitPath = (to, resource, data = null, parent = null) => {
    if (
      resource == "chapters" ||
      resource == "sections" ||
      resource == "pages"
    ) {
      let pathParts = [
        {
          text: "Inicio",
          exact: true,
          to: "/",
        },
        {
          text: "Guías",
          exact: true,
          to: "/books",
        },
      ];

      let book = undefined;
      let chapter = undefined;
      let section = undefined;
      if (resource == "chapters") {
        book = data ? data.book : parent;
      } else if (resource == "sections") {
        book = data ? data.chapter.book : parent.book;
        chapter = data ? data.chapter : parent;
      } else {
        if (data) {
          if (data.page_id == null) {
            book = data.chapter.book;
            chapter = data.chapter;
          } else {
            book = data.section.chapter.book;
            chapter = data.section.chapter;
            section = data.section;
          }
        } else {
          if (parent.chapter_id) {
            book = parent.chapter.book;
            chapter = parent.chapter;
            section = parent;
          } else {
            book = parent.book;
            chapter = parent;
          }
        }
      }

      pathParts.push({
        text: truncate(book.title, {
          'length': 30,
          'separator': ' '
        }),
        exact: true,
        to: `/books/${book.id}`,
      });

      if (resource == "sections") {
        pathParts.push({
          text: truncate(chapter.title, {
            'length': 30,
            'separator': ' '
          }),
          exact: true,
          to: `/chapters/${chapter.id}`,
        });
      }

      if (resource == "pages") {
        pathParts.push({
          text: truncate(chapter.title, {
            'length': 30,
            'separator': ' '
          }),
          exact: true,
          to: `/chapters/${chapter.id}`,
        });

        if (section) {
          pathParts.push({
            text: truncate(section.title, {
              'length': 30,
              'separator': ' '
            }),
            exact: true,
            to: `/sections/${section.id}`,
          });
        }
      }

      if (data) {
        pathParts.push({
          text: data.title,
          exact: true,
          to: `/${resource}/${data.id}`,
        });
      } else {
        pathParts.push({
          text: to.meta.title,
          exact: true,
          to: to.path,
        });
      }

      to.meta.pathParts = pathParts;
    }

    if (
      resource == "stat_sections" ||
      resource == "stat_question_sections" ||
      resource == "stat_questions" ||
      resource == "stat_questions_options"
    ) {
      let pathParts = [
        {
          text: "Inicio",
          exact: true,
          to: "/",
        },
        {
          text: "Estratificaciones",
          exact: true,
          to: "/stats",
        },
      ];

      let stat = undefined;
      let statSection = undefined;
      let epigraph = undefined;
      let statQuestion = undefined;
      if (resource == "stat_sections") {
        stat = data ? data.stat : parent;
      } else if (resource == "stat_question_sections") {
        stat = data ? data.section.stat : parent.stat;
        statSection = data ? data.section : parent;
      } else if (resource == "stat_questions") {
        if (data) {
          if (data.stat_question_id == null) {
            stat = data.section.stat;
            statSection = data.section;
          } else {
            stat = data.parent.section.stat;
            statSection = data.parent.section;
            epigraph = data.parent;
          }
        } else {
          if (parent.stat_section_id) {
            stat = parent.section.stat;
            statSection = parent.section;
            epigraph = parent;
          } else {
            stat = parent.stat;
            statSection = parent;
          }
        }
      } else {
        statQuestion = data ? data.question : parent;
        if (statQuestion.stat_question_id == null) {
          stat = statQuestion.section.stat;
          statSection = statQuestion.section;
        } else {
          stat = statQuestion.section.section.stat;
          statSection = statQuestion.section;
          epigraph = statQuestion.parent;
        }
      }

      pathParts.push({
        text: truncate(stat.title, {
          'length': 30,
          'separator': ' '
        }),
        exact: true,
        to: `/stats/${stat.id}`,
      });

      if (resource == "stat_question_sections") {
        pathParts.push({
          text: truncate(statSection.title, {
            'length': 30,
            'separator': ' '
          }),
          exact: true,
          to: `/stat-sections/${statSection.id}`,
        });
      }

      if (resource == "stat_questions"  || resource == "stat_questions_options") {
        pathParts.push({
          text: truncate(statSection.title, {
            'length': 30,
            'separator': ' '
          }),
          exact: true,
          to: `/stat-sections/${statSection.id}`,
        });

        if (epigraph) {
          pathParts.push({
            text: truncate(epigraph.title, {
              'length': 30,
              'separator': ' '
            }),
            exact: true,
            to: `/stat-question-sections/${epigraph.id}`,
          });
        }
      }

      if (resource == "stat_questions_options") {
        if (statQuestion) {
          pathParts.push({
            text: truncate(statQuestion.title, {
              'length': 30,
              'separator': ' '
            }),
            exact: true,
            to: `/stat-questions/${statQuestion.id}`,
          });
        }
      }

      if (data) {
        pathParts.push({
          text: data.title,
          exact: true,
          to: `/${replace(resource, /_/g, '-')}/${data.id}`,
        });
      } else {
        pathParts.push({
          text: to.meta.title,
          exact: true,
          to: to.path,
        });
      }

      to.meta.pathParts = pathParts;
    }
  };

  /**
   * Action route builder
   */
  const buildRoute = (action, path) => {
    return {
      path,
      name: `${name}_${action}`,
      props: true,
      component: {
        props: ["id", "parent"],
        render(c) {
          let componentName = `${upperFirst(camelCase(name))}${upperFirst(
            action
          )}`;

          let props = {
            id: this.id,
            title: this.$route.meta.title,
            resource,
            item: store.state[name].item,
            parent: this.parent,
            permissions: store.getters["auth/getPermissions"],
          };

          if (componentName in this.$options.components) {
            /**
             * Return client side page component
             */
            return c(componentName, {
              props,
            });
          }

          /**
           * Return guesser page component
           */
          return c(`Va${upperFirst(action)}Guesser`, {
            props,
          });
        },
        async beforeRouteEnter(to, from, next) {
          /**
           * Initialize from query if available
           */
          let id = to.params.id || to.query.source;

          if (id) {
            /**
             * Route model binding
             */
            try {
              let { data } = await store.dispatch(`${name}/getOne`, {
                id,
                include,
              });

              /**
               * Insert model into route & resource store
               */
              store.commit(`${name}/setItem`, data);

              if (to.params.id) {
                setTitle(to, action, data);
                splitPath(to, name, data);
                return next();
              }
            } catch ({ status, message }) {
              to.meta.title = message;
              document.title = message;

              store.commit(`messages/setError`, {
                status,
                message:
                  status === 404
                    ? i18n.t("va.pages.not_found", {
                        resource: resource.singularName,
                        id,
                      })
                    : message,
              });
              return next();
            }
          } else {
            let parent = to.params.parent;
            if (parent) {
              setTitle(to, action);
              splitPath(to, name, null, parent);
              return next();
            }
          }

          setTitle(to, action);
          next();
        },
        beforeRouteLeave(to, from, next) {
          store.commit(`${name}/removeItem`);
          next();
        },
      },
      meta: {
        authenticated: true,
        resource: name,
        translatable,
      },
    };
  };

  /**
   * Return crud routes for this resource
   */
  return {
    path: `/${kebabCase(name)}`,
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      title: pluralName,
    },
    children: [
      { name: "list", path: "" },
      { name: "create", path: "create" },
      { name: "show", path: ":id" },
      { name: "edit", path: ":id/edit" },
    ]
      .filter(({ name }) => routes.includes(name))
      .map(({ name, path }) => buildRoute(name, path)),
  };
};
