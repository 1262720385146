import Vue from "vue";
import VuetifyAdmin from "../";

import "vuetify-admin/src/loader";

import {
  laravelDataProvider,
  sanctumAuthProvider,
} from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";
import es from "../i18n/es.json";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";
import trimEnd from "lodash/trimEnd";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Self Admin",
  routes,
  locales: {
    en,
    es,
  },
  translations: ["en", "es"],
  dataProvider: laravelDataProvider(http),
  authProvider: sanctumAuthProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
    tinyMCE: {
      language: "es",
      imageUploadUrl: "/api/upload",
      fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`,
      plugins: [
        "print",
        "preview",
        "paste",
        "searchreplace",
        "autolink",
        "directionality",
        "code",
        "visualblocks",
        "visualchars",
        "fullscreen",
        "image",
        "link",
        "media",
        "table",
        "charmap",
        "hr",
        "anchor",
        "insertdatetime",
        "advlist",
        "lists",
        "wordcount",
        "imagetools",
        "help",
        "charmap",
        "emoticons",
      ],
      toolbar:
        "undo redo | fontsizeselect formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link anchor codesample | ltr rtl | help",
    
      },
  },
});
