<template>
  <custom-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ item.title }}
              </div>
            </template>
            <v-card-text>
              <template v-if="item && chapters && chapters.length > 0">
                <v-list two-line>
                  <draggable
                    v-model="chapters"
                    handle=".handle"
                    class="list-group"
                    v-bind="dragOptions"
                    @update="update"
                    draggable=".draggable"
                  >
                    <transition-group type="transition" name="flip-list">
                      <v-list-item
                        v-for="(chapter, index) in chapters"
                        :key="chapter.id"
                        :to="{
                          name: 'chapters_show',
                          params: { id: chapter.id, parent: item },
                        }"
                        class="chapter-item draggable elevation-1"
                      >
                        <v-list-item-avatar>
                          <v-icon> mdi-book-multiple </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="text-uppercase">
                            Capítulo {{ index + 1 }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="text-uppercase"
                            v-text="chapter.title"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar class="handle">
                          <v-icon> mdi-reorder-horizontal </v-icon>
                        </v-list-item-avatar>
                      </v-list-item>
                    </transition-group>
                  </draggable>
                </v-list>
              </template>
              <template v-else>
                <div class="text-center">
                  <h3 class="display-1 font-weight-light text--primary my-5">
                    No se han creado capítulos para este guía.
                  </h3>
                  <va-action-button
                    :to="{ name: 'chapters_create', params: { parent: item } }"
                    icon="mdi-book-plus-multiple"
                    label="Crear Capítulo"
                  />
                </div>
              </template>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-8">
            <v-list color="transparent" subheader v-if="item">
              <v-subheader>Patrocinador</v-subheader>
              <v-list-item v-if="item.sponsor">
                <v-img
                  v-if="item.sponsor"
                  class="white--text align-end"
                  :src="item.sponsor.thumbnails.large"
                >
                </v-img>
              </v-list-item>
              <v-list-item v-if="!item.sponsor">
                No disponible
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list color="transparent" subheader v-if="item">
              <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-star </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Creado {{ $helpers.dateFromNow(item.created_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-pen </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Modificado {{ $helpers.dateFromNow(item.updated_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            
            <v-list color="transparent" subheader>
              <v-subheader>Acciones</v-subheader>
              <v-list-item
                :to="{ name: 'chapters_create', params: { parent: item } }"
              >
                <v-list-item-icon>
                  <v-icon> mdi-book-plus-multiple </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Crear capítulo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </custom-show-layout>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["item"],
  components: {
    draggable,
  },
  data() {
    return {
      chapters: this.item.chapters,
    };
  },
  methods: {
    async update() {
      this.chapters.map((el, index) => {
        el.priority = index + 1;
        this.$store.dispatch("chapters/update", {
          id: el.id,
          data: { priority: el.priority },
        });
      });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true,
        fallbackClass: "clone_card",
      };
    },
  },
};
</script>
<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.clone {
  opacity: 1 !important;
  box-shadow: 0px 0px 2px 0px;
  background-color: #000000;
}

.clone_card {
  opacity: 1 !important;
}

.handle:hover {
  cursor: move;
}
</style>
