<template>
  <custom-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="8">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ item.title }}
              </div>
            </template>
            <v-card-text>
              <div class="smartphone-preview">
                <div class="content">
                  <div class="preview-page-title" v-if="item != null">
                    <v-icon large color="purple lighten-2 ">mdi-chevron-left-circle</v-icon> 1 {{ item.title }}
                  </div>
                  <div
                    v-if="item != null"
                    v-html="item.html"
                    style="width: 100%; border: none; height: 100%"
                    class="smartphone-preview-content"
                  ></div>
                </div>
              </div>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-8">
            <v-list color="transparent" subheader>
              <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-book-open-page-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ book.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-book-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ chapter.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="section">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ section.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </custom-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
  data() {
    return {
      book: this.item.page_id
        ? this.item.section.chapter.book
        : this.item.chapter.book,
      chapter: this.item.page_id
        ? this.item.section.chapter
        : this.item.chapter,
      section: this.item.page_id ? this.item.section : undefined,
    };
  },
};
</script>
