<template>
  <va-form ref="form" :id="id" :item="item">
    <v-row justify="center">
      <v-col lg="6">
        <base-material-tabs-card
          :tabs="[
            { id: 'attributes', icon: 'mdi-text' },
            { id: 'results', icon: 'mdi-eye' },
          ]"
        >
          <template v-slot:attributes>
            <v-row>
              <v-col>
                <va-text-input source="title"></va-text-input>
                <va-text-input source="summary" multiline></va-text-input>
                <custom-horizontal-file-input source="sponsor" accept="image/png, image/jpeg" preview></custom-horizontal-file-input>
              </v-col>
            </v-row>
          </template>
          <template v-slot:results>
            <va-number-input
              required
              source="max_score"
              :step="1"
              :min="0"
              format="integer"
            ></va-number-input>
            <v-divider class="mb-4"></v-divider>
            <h3>Niveles de estratificación</h3>

            <div class="px-4 py-4 mt-4 level_red">
              <h4 class="mb-2">{{ $t("result_level_1") }}</h4>
              <v-row>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_1.max"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_1.min"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-text-input
                    source="result_level_1.text"
                    hide-details="auto"
                    multiline
                  ></va-text-input>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div class="px-4 py-4 mt-4 level_orange">
              <h4 class="mb-2">{{ $t("result_level_2") }}</h4>
              <v-row>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_2.max"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_2.min"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-text-input
                    source="result_level_2.text"
                    hide-details="auto"
                    multiline
                  ></va-text-input>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div class="px-4 py-4 mt-4 level_green">
              <h4 class="mb-2">{{ $t("result_level_3") }}</h4>
              <v-row>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_3.max"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
                <v-col sm="6">
                  <va-number-input
                    source="result_level_3.min"
                    :step="1"
                    :min="0"
                    format="integer"
                    hide-details="auto"
                  ></va-number-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-text-input
                    source="result_level_3.text"
                    hide-details="auto"
                    multiline
                  ></va-text-input>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:footer>
            <va-save-button></va-save-button>
          </template>
        </base-material-tabs-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      disabled: false,
    };
  }, 
};
</script>

<style>
.level_green {
  border-left: 5px solid green;
}
.level_orange {
  border-left: 5px solid orange;
}
.level_red {
  border-left: 5px solid red;
}
</style>
