<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :items-per-page="9"
      :items-per-page-options="[6, 9, 18, 36]"
    >
      <template v-slot="{ items }">
        <v-row>
          <v-col lg="4" md="6" cols="12" v-for="item in items" :key="item.id">
            <custom-material-card-list-item
              color="info"
              icon="mdi-book-open-page-variant"
              :title="item.title"
              sub-icon="mdi-clock"
              :sub-text="$helpers.dateFromNow(item.updated_at)"
              :to="{ name: 'books_show', params: { id: item.id } }"
              class="text-uppercase"
            />
          </v-col>
        </v-row>
      </template>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["title"],
      sortBy: ["updated_at"],
      sortDesc: [true],
    };
  },
};
</script>
