<template>
  <custom-edit-layout>
    <sections-form :id="id" :title="title" :item="item" :chapter="chapter" />
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: "Editar sección - " + this.item.title,
      chapter: this.item.chapter,
    };
  },
};
</script>
