<template>
  <va-create-layout>
    <books-form :title="title" :item="item"></books-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      title: "Crear nueva guía",
    };
  },
};
</script>
