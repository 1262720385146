<template>
  <va-create-layout>
    <stat-sections-form :title="title" :stat="parent"></stat-sections-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "parent", "item"],
};
</script>
