<template>
  <va-create-layout>
    <sections-form :title="title" :chapter="parent"></sections-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["parent", "item"],
  data() {
    return {
      title: "Crear nueva sección de páginas",
    };
  },
};
</script>
