<template>
  <va-form :resource="resource" :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-hidden-input
      type="hidden"
      source="stat_question_id"
      :value="parent.id"
    ></va-hidden-input>
    <va-text-input source="title" required></va-text-input>
    <va-number-input source="weight" required></va-number-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["resource", "id", "item", "parent"],
};
</script>
