<template>
  <custom-form
    :id="id"
    :item="item"
    redirect="show"
    :parentResource="parentRes"
    :parentResourceId="parent.id"
  >
    <v-row justify="center">
      <v-col sm="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-hidden-input
              v-if="parentRes == 'chapters' && chapter"
              type="hidden"
              source="chapter_id"
              :value="chapter.id"
            ></va-hidden-input>
            <va-hidden-input
              v-if="parentRes == 'sections' && section"
              type="hidden"
              source="page_id"
              :value="section.id"
            ></va-hidden-input>
            <va-hidden-input
              type="hidden"
              source="type"
              :value="type"
            ></va-hidden-input>
            <va-text-input source="title"></va-text-input>
            <va-rich-text-input source="html" :init="tinymceInitOptions" ></va-rich-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mt-8">
          <v-list color="transparent" subheader>
            <v-subheader>Detalles</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-book-open-page-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap font-weight-bold text-uppercase"
                >
                  {{ chapter.book.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap font-weight-bold text-uppercase"
                >
                  {{ chapter.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="section">
              <v-list-item-icon>
                <v-icon>mdi-file-document-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap font-weight-bold text-uppercase"
                >
                  {{ section.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </custom-form>
</template>

<script>

import trimEnd from "lodash/trimEnd";

export default {
  props: ["title", "id", "item", "parent", "parentRes", "chapter", "section"],
  data() {
    return {
      type: "page",
    };
  },
  computed: {
    tinymceInitOptions() {
      const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

      return {
        height: 500,
        menubar: true,
        language: "es",
        imageUploadUrl: "/api/upload",
        fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`,
        document_base_url: `${baseURL}/`,
        relative_urls: false,
        remove_script_host : false,
        plugins: [
          "print",
          "preview",
          "paste",
          "searchreplace",
          "autolink",
          "directionality",
          "code",
          "visualblocks",
          "visualchars",
          "fullscreen",
          "image",
          "link",
          "media",
          "table",
          "charmap",
          "hr",
          "anchor",
          "insertdatetime",
          "advlist",
          "lists",
          "wordcount",
          "imagetools",
          "help",
          "charmap",
          "emoticons",
        ],
        toolbar:
          "undo redo | fontsizeselect formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link anchor codesample | ltr rtl | help",
      };
    },

  },
};
</script>
