<template>
  <custom-form
    :id="id"
    :item="item"
    redirect="show"
    :parentResourceId="section.id"
  >
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-hidden-input
              type="hidden"
              source="stat_section_id"
              :value="section.id"
            ></va-hidden-input>
            <va-hidden-input
              type="hidden"
              source="type"
              value="section"
            ></va-hidden-input>
            <va-text-input source="title"></va-text-input>
            <va-text-input source="summary" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mt-8">
          <v-list color="transparent" subheader>
            <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-chart-line </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ section.stat.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-chart-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ section.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </custom-form>
</template>

<script>
export default {
  props: ["title", "id", "item", "section"],
};
</script>
