<template>
  <v-input v-bind="$props">
    <input type="hidden" :value="value" />
  </v-input>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";

export default {
  mixins: [Input],
  props: {
    /**
     * Value to be edited.
     * @model
     */
    value: {
      type: [String, Number],
    },
  },
};
</script>
