var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('custom-show-layout',[_c('va-show',{attrs:{"item":_vm.item}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2"},[_vm._v(" Variable: "+_vm._s(_vm.title)+" ")])]},proxy:true}])},[_c('v-card-text',[(_vm.item && _vm.item.summary)?_c('div',{staticClass:"mb-4"},[_c('va-text-field',{attrs:{"source":"summary"}})],1):_vm._e(),(_vm.item && _vm.questions && _vm.questions.length > 0)?[_c('v-list',{attrs:{"two-line":""}},[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"disabled":_vm.questions.length == 1,"handle":".handle","draggable":".draggable"},on:{"update":_vm.update},model:{value:(_vm.questions),callback:function ($$v) {_vm.questions=$$v},expression:"questions"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.questions),function(question){return _c('v-list-item',{key:question.id,staticClass:"draggable",class:question.type == 'section' ? 'section-item' : 'page-item',attrs:{"to":{
                        name: ("" + (question.type == 'section'
                            ? 'stat_question_sections_show'
                            : 'stat_questions_show')),
                        params: { id: question.id, parent: _vm.item },
                      }}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(question.type == "section" ? "mdi-format-list-checks" : "mdi-beaker-question-outline"))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(question.title)}})],1),(_vm.questions.length > 1)?_c('v-list-item-avatar',{staticClass:"handle"},[_c('v-icon',[_vm._v(" mdi-reorder-horizontal ")])],1):_vm._e()],1)}),1)],1)],1)]:[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"display-1 font-weight-light text--primary my-5"},[_vm._v(" No se han creado epígrafes o preguntas en esta variable. ")]),_c('div',{staticClass:"d-flex justify-space-around pa-2"},[_c('va-action-button',{attrs:{"to":{
                      name: 'stat_question_sections_create',
                      params: { parent: _vm.item },
                    },"icon":"mdi-format-list-checks","label":"Crear Epígrafe","color":"#077b70"}}),_c('va-action-button',{attrs:{"to":{
                      name: 'stat_questions_create',
                      params: { parent: _vm.item },
                    },"icon":"mdi-beaker-question-outline","label":"Crear Pregunta","color":"#af4d0d"}})],1)])]],2)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"mt-8"},[(_vm.item)?_c('v-list',{attrs:{"color":"transparent","subheader":""}},[_c('v-subheader',[_vm._v("Detalles")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-chart-line ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.stat.title)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-marker-check ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Puntuación máxima: "+_vm._s(_vm.item.max_score)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-star ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Creado "+_vm._s(_vm.$helpers.dateFromNow(_vm.item.created_at))+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-pen ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Modificado "+_vm._s(_vm.$helpers.dateFromNow(_vm.item.updated_at))+" ")])],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-list',{attrs:{"color":"transparent","subheader":""}},[_c('v-subheader',[_vm._v("Acciones")]),_c('v-list-item',{attrs:{"to":{
                name: 'stat_question_sections_create',
                params: { parent: _vm.item },
              }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-format-list-checks ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Crear epígrafe")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'stat_questions_create', params: { parent: _vm.item } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-beaker-question-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Crear pregunta")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }