<template>
  <custom-edit-layout>
    <stat-questions-form
      :id="id"
      :item="item"
      :title="title"
      :parent="parent"
      :parentRes="parentRes"
      :epigraph="epigraph"
      :section="section"
    ></stat-questions-form>
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: "Editar pregunta: " + this.item.title,
      parent: this.item.stat_question_id ? this.item.parent : this.item.section,
      parentRes: this.item.stat_question_id ? "stat_question_sections" : "stat_sections",
      section: this.item.stat_question_id
        ? this.item.parent.section
        : this.item.section,
      epigraph: this.item.stat_question_id ? this.item.parent : undefined,
    };
  },
};
</script>
