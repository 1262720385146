<template>
 
  <custom-show-layout>
    <va-aside-layout :title="asideTitle">
       <stat-question-options-form resource="stat_question_options" :id="optionId" :item="option" :parent="element" @saved="onSaved"></stat-question-options-form>
    </va-aside-layout>
    <va-show :item="element">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                Pregunta: {{ title }}
              </div>
            </template>
            <v-card-text>
              <div class="mb-4" v-if="element && element.summary">
                <va-text-field source="summary"></va-text-field>
              </div>
              <template v-if="element && options && options.length > 0">
                <v-list two-line>
                  <draggable
                    v-model="options"
                    :disabled="options.length == 1"
                    handle=".handle"
                    class="list-group"
                    v-bind="dragOptions"
                    @update="update"
                    draggable=".draggable"
                  >
                    <transition-group type="transition" name="flip-list">
                      <v-list-item
                        v-for="option in options"
                        :key="option.id"
                       @click="onAction('edit', option)"
                        class="page-item draggable"
                      >
                        <v-list-item-avatar>
                          <v-icon> mdi-timeline-check-outline </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-uppercase"
                            v-text="option.title"
                          ></v-list-item-title>
                           <v-list-item-subtitle class="font-weight-bold" v-text="`Peso: ${option.weight}`"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar
                          class="handle"
                          v-if="options.length > 1"
                        >
                          <v-icon> mdi-reorder-horizontal </v-icon>
                        </v-list-item-avatar>
                      </v-list-item>
                       
                    </transition-group>
                  </draggable>
                  <v-list-item
                        v-if="element.not_available"  class="page-item"
                      >
                        <v-list-item-avatar>
                          <v-icon> mdi-timeline-check-outline </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-uppercase"
                          >No Disponible</v-list-item-title>
                           <v-list-item-subtitle class="font-weight-bold">Peso: 0</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                </v-list>
              </template>
              <template v-else>
                <div class="text-center">
                  <h3 class="display-1 font-weight-light text--primary my-5">
                    No se han creado respuesta en para esta pregunta.
                  </h3>
                  <div class="d-flex justify-space-around pa-2">
                    <va-action-button
                      @click="onAction('create', null)"
                      icon="mdi-timeline-check-outline"
                      label="Crear Respuesta"
                      color="#af4d0d"
                    />
                  </div>
                </div>
              </template>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-8">
            <v-list color="transparent" subheader v-if="item">
              <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-chart-line </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ section.stat.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-chart-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ section.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="epigraph">
                <v-list-item-icon>
                  <v-icon>mdi-format-list-checks</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap font-weight-bold text-uppercase"
                  >
                    {{ epigraph.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-information-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold text-uppercase">Mostrar "No disponible": </span>{{ item.not_available ? 'Sí':'No'}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-star </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Creado {{ $helpers.dateFromNow(element.created_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-pen </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Modificado {{ $helpers.dateFromNow(element.updated_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list color="transparent" subheader>
              <v-subheader>Acciones</v-subheader>
              <v-list-item
                @click="onAction('create', null)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-timeline-check-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Crear respuesta</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </custom-show-layout>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["item", "parent"],
  components: {
    draggable,
  },
  data() {
    return {
      element: this.item,
      title: this.item.title,
      section: this.item.stat_question_id
        ? this.item.parent.section
        : this.item.section,
      epigraph: this.item.stat_question_id ? this.item.parent : undefined,
      options: this.item.options,
      asideTitle: null,
      optionId: null,
      option: null,
    };
  },
  methods: {
    async update() {
      this.questions.map((el, index) => {
        el.priority = index + 1;
        this.$store.dispatch("stat_question_options/update", {
          id: el.id,
          data: { priority: el.priority },
        });
      });
    },
    async onAction(action, option) {
      if(action == 'edit'){
        this.asideTitle = 'Editar respuesta';
        this.optionId = option.id;
        this.option = option;
      } else {
        this.asideTitle = 'Crear respuesta';
        this.optionId = null;
        this.option = null;
      }
    },
    async onSaved() {
      this.asideTitle = null;
      this.optionId = null;
      this.option = null;
      let response = await this.$store.dispatch("stat_questions/getOne", {
         id: this.item.id,
      });
      this.element = response.data;
      this.options = this.element.options
      
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        ghostClass: "ghost",
        forceFallback: true,
        fallbackClass: "clone_card",
      };
    },

  },
};
</script>
