<template>
  <custom-edit-layout>
    <pages-form
      :id="id"
      :item="item"
      :title="title"
      :parent="parent"
      :parentRes="parentRes"
      :section="section"
      :chapter="chapter"
    />
  </custom-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      title: "Editar página - " + this.item.title,
      parent: this.item.page_id ? this.item.section : this.item.chapter,
      parentRes: this.item.page_id ? "sections" : "chapters",
      chapter: this.item.page_id
        ? this.item.section.chapter
        : this.item.chapter,
      section: this.item.page_id ? this.item.section : undefined,
    };
  },
};
</script>
