<template>
    <div>
        <v-row align="center">
            <v-col lg="6" align="center">
                <!-- multiple as v-bind because of vuetify v-model bug forced to array -->
                <v-file-input
                    v-bind="multiple ? { ...commonProps, multiple: true } : commonProps"
                    :filled="filled"
                    :chips="chips"
                    :small-chips="smallChips"
                    :accept="accept"
                    @change="update"
                ></v-file-input>
            </v-col> 
            <!-- @slot Default slot if you need to replace default VaFileField or VaImageField. -->
            <slot>
                <v-col lg="6" align="center">
                    <component
                        :is="`va-${preview ? 'image' : 'file'}-field`"
                        :source="source"
                        :item="formState.item"
                        v-bind="$attrs"
                        clearable
                        :item-value="itemValue"
                    ></component>
                </v-col>
            </slot>
        </v-row>
    </div>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Multiple from "vuetify-admin/src/mixins/multiple";

/**
 * Allow row file uploads. Can be multiple.
 * Current files will be shown as simple link or thumbnail image and can be deleted.
 * Use VaFileField or VaImageField under the hood.
 * No Ajax support.
 */
export default {
  mixins: [Input, Multiple],
  props: {
    /**
     * Show file thumbnail image instead of simple link.
     */
    preview: {
      type: Boolean,
      default: false,
    },
    /**
     * Attribute where taking the id value for identify files to delete.
     */
    itemValue: {
      type: String,
      default: "id",
    },
    /**
     * Add HTML5 `accept` attribute for simple client-side validation.
     */
    accept: String,
  },
  data() {
    return {
      acceptValue: false,
    };
  },
};
</script>