<template>
  <va-create-layout>
    <stat-questions-form
      :title="title"
      :parent="parent"
      :parentRes="parentRes"
      :section="section"
      :epigraph="epigraph"
    ></stat-questions-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["parent", "item"],
  data() {
    return {
      title: "Crear nueva pregunta",
      epigraph:
        typeof this.parent.children == "object" ? this.parent : undefined,
      section:
        typeof this.parent.children == "undefined"
          ? this.parent
          : this.parent.section,
      parentRes:
        typeof this.parent.children == "undefined" ? "stat_sections" : "stat_question_sections",
    };
  },
};
</script>
